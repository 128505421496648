<template>
  <div ref="container" :class="playStationPartnershipClasses">
    <ClientOnly>
      <PartnershipBanner v-if="partnerId" :partner="partnerId" />
    </ClientOnly>

    <div
      v-if="isLoadingScreen"
      class="flex min-h-screen flex-col items-center justify-center"
    >
      <div>
        <RevSpinner class="m-auto" />
      </div>
      <p class="body-2">
        {{ i18n(translations.loading) }}
      </p>
    </div>

    <div
      v-if="!isLoadingScreen"
      class="flex min-h-[calc(100vh-3.75rem)] pb-72 lg:pb-0"
    >
      <div
        class="bg-surface-default-hi hidden lg:block lg:w-[35.5rem] lg:pl-160 lg:pt-32"
      >
        <RevLink
          class="flex items-center lg:p-12"
          data-test="back-link"
          @click="goBack"
        >
          <IconArrowLeft size="small" />
          <span class="ml-12">
            {{ backLink.label }}
          </span>
        </RevLink>

        <DeviceSummary
          v-if="displaySummary"
          data-test="device-summary"
          :summary="summary"
        />

        <RevIllustration
          alt="summary"
          class="mb-32 mt-72"
          :height="180"
          src="/img/buyback/buybackFunnel.svg"
          :width="329"
        />
      </div>
      <div class="grow scroll-mt-96 p-16 lg:w-auto lg:px-72 lg:py-32">
        <RevStepper
          v-if="activeStepName"
          :active-step="activeStepName"
          alternative-text-back="back"
          alternative-text-close="close"
          alternative-text-completed="completed"
          :alternative-text-current="activeStepName"
          class="mb-24 md:mx-auto md:w-[39.5rem]"
          data-test="stepper"
          :has-back="stepperHasBackButton"
          :steps="steps"
          @back="goBack"
        />

        <QuestionsForm
          v-if="displayFunnel"
          :active-step="activeStep"
          class="md:m-auto md:w-[39.5rem]"
          :funnel="funnel"
          :is-loading-offer="isLoadingOffer"
          @next-question="getNextQuestion"
          @next-step="getNextStep"
          @submit-answers="getOffer"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { createError, useRoute, useRouter } from '#imports'
import { computed, ref } from 'vue'
import type { LocationQuery } from 'vue-router'

import { getOfferV1 } from '@backmarket/http-api/src/api-specs-buyback/customer/getOfferV1'
import { getQuestionsV3 } from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { HttpApiError } from '@backmarket/http-api/src/utils/HttpApiError'
import { HttpEvent } from '@backmarket/http-api/src/utils/types'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { useBreakpoint } from '@backmarket/utils/composables/useBreakpoint'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { RevSpinner } from '@ds/components/Spinner'
import { RevStepper } from '@ds/components/Stepper'
import { IconArrowLeft } from '@ds/icons/IconArrowLeft'

import { getOfferAdapter } from '~/scopes/buyback/api/adapters/getOfferAdapter.adapter'
import { FUNNEL_ERROR_MESSAGE } from '~/scopes/buyback/pages/constants'
import { useBuyBackStore } from '~/scopes/buyback/store'

import DeviceSummary from '../components/DeviceSummary/DeviceSummary.vue'
import PartnershipBanner from '../components/PartnershipBanner/PartnershipBanner.vue'
import QuestionsForm, {
  type NextQuestionPayload,
  type NextStepPayload,
  type SubmitAnswersPayload,
} from '../components/QuestionsForm/QuestionsForm.vue'
import { ROUTE_NAMES } from '../constants'

import translations from './DeviceAssessment.translations'

const i18n = useI18n()
const router = useRouter()
const route = useRoute()
const logger = useLogger()
const breakpoint = useBreakpoint()
const tracking = useTracking()
const { openErrorToast } = useTheToast()
const store = useBuyBackStore()

const scrollToTop = ref(false)
const isLoadingScreen = ref(true)
const isLoadingOffer = ref(false)

const { params } = route

const container = ref<HTMLElement | null>(null)

const queryParamsPayload = computed(() => ({
  ...route.query,
  category: params.id,
}))

const { data: buybackQuestions, error: questionsError } = await useHttpFetch(
  getQuestionsV3,
  {
    queryParams: queryParamsPayload,
    pathParams: {
      kind: 'regular',
    },
    server: false,
    onEvent: (event) => {
      if (event === HttpEvent.Success) {
        isLoadingScreen.value = false
      }

      if (event === HttpEvent.Success && scrollToTop.value) {
        container.value?.scrollIntoView({
          block: 'start',
        })
        scrollToTop.value = false
      }
    },
  },
)

if (questionsError.value) {
  // fatal: true option redirects to Error view
  throw createError({
    statusCode: questionsError.value.statusCode,
    statusMessage: questionsError.value.message,
    fatal: true,
  })
}

const funnel = computed(() => buybackQuestions.value?.funnel || [])
const summary = computed(() => buybackQuestions.value?.summary || [])

const partnerId = computed(() => {
  const { partner } = route.query

  return partner && typeof partner === 'string' && partner.toLowerCase()
})

const playStationPartnershipClasses = computed(() => {
  // this will make sure scrollIntoView take into account header and banner
  return partnerId.value ? 'scroll-mt-[200px]' : ''
})

const displaySummary = computed(() => {
  return !isEmpty(summary.value)
})

const backLink = computed(() => {
  const { questions = [] } =
    (funnel.value && funnel.value.find(({ step }) => step?.active)) || {}
  const previousStep = funnel.value.findIndex(({ step }) => step?.active) - 1

  const routeQuery: LocationQuery = {
    ...route.query,
    nextStep: 'false',
  }
  const isNotAtFirstStep = previousStep >= 0
  const toRoute = isNotAtFirstStep
    ? route
    : {
        name: 'buyback',
        params: {
          pageName: 'home',
        },
      }

  questions.forEach(({ key }) => {
    delete routeQuery[key]
  })

  return {
    label:
      (funnel.value[previousStep] && funnel.value[previousStep]?.step?.label) ||
      i18n(translations.goBack),
    to: {
      ...toRoute,
      ...(isNotAtFirstStep && {
        query: routeQuery,
      }),
    },
  }
})

const steps = computed(() => {
  return funnel.value.map(({ step }) => {
    if (!step) {
      return {
        label: '',
        name: '',
      }
    }

    return {
      label: step.label,
      name: step.key,
    }
  })
})

const activeStep = computed(() => {
  const activeFunnelStep = funnel.value.find(({ step }) => step?.active)

  return activeFunnelStep?.step || null
})

const activeStepName = computed(() => {
  return activeStep.value?.key || ''
})

const stepperHasBackButton = computed(() => {
  return breakpoint.value <= 2
})

const displayFunnel = computed(() => {
  return !isEmpty(funnel.value)
})

const goBack = () => {
  router.replace(backLink.value.to)
}

const getNextQuestion = ({
  formPayload = {},
  reset = [],
}: NextQuestionPayload) => {
  const formGlobal = { ...route.query, ...formPayload }

  if (!isEmpty(reset)) {
    reset.forEach((key) => {
      delete formGlobal[key as keyof typeof formGlobal]
    })
  }

  router.replace({
    ...route,
    query: formGlobal,
  })
}

const formatTrackingPayload = (payload: Record<string, string | string[]>) => {
  const formattedPayload = {
    ...payload,
  }
  delete formattedPayload.nextStep

  return formattedPayload
}

const getNextStep = ({
  formPayload,
  currentStep,
  shouldScrollToTop,
}: NextStepPayload) => {
  if (formPayload.nextStep) {
    tracking.trackClick({
      zone: 'buyback',
      name: currentStep || '',
      value: formatTrackingPayload({
        category: route.params.id,
        ...route.query,
        ...formPayload,
      }),
    })
  }
  getNextQuestion({ formPayload, reset: [] })

  scrollToTop.value = shouldScrollToTop
}

const getOffer = async ({ formPayload, currentStep }: SubmitAnswersPayload) => {
  isLoadingOffer.value = true
  const queryParams = {
    category: params.id,
    ...route.query,
    ...formPayload,
  }

  const { model: name, ...restPayload } = formatTrackingPayload(queryParams)
  tracking.trackBuybackDeviceSpecifications({
    product: {
      ...restPayload,
      name,
    },
  })

  tracking.trackClick({
    zone: 'buyback',
    name: currentStep || '',
    value: formatTrackingPayload(queryParams),
  })

  tracking.trackClick({
    zone: 'buyback',
    name: 'see_estimate',
    value: formatTrackingPayload(queryParams),
  })

  try {
    const getOfferV1Payload = await $httpFetch(getOfferV1, {
      queryParams: {
        ...queryParams,
        category: route.params?.id,
      },
      pathParams: {
        kind: 'regular',
      },
    })
    const getOfferLegacyPayload = getOfferAdapter(
      getOfferV1Payload,
      queryParams,
      i18n.currencySign,
    )

    if (getOfferLegacyPayload) {
      store.setBuyBackOffer(getOfferLegacyPayload, false)

      const listingId = getOfferLegacyPayload.sourcing_listing.toString()

      router.push({
        name: ROUTE_NAMES.OFFER,
        params: {
          ...route.params,
          listingId,
        },
        query: {
          ...queryParams,
          listingPublicId: getOfferV1Payload.listing.listingPublicId,
        },
      })
    }
  } catch (error) {
    const httpError = error as HttpApiError

    if (httpError.status === 404) {
      tracking.trackBuybackNoOffer({
        value: formatTrackingPayload(queryParams),
      })

      router.push({
        name: ROUTE_NAMES.NO_OFFER,
        params: route.params,
      })
    } else {
      logger.error(FUNNEL_ERROR_MESSAGE.GET_OFFER)
      openErrorToast()
    }
  } finally {
    isLoadingOffer.value = false
  }
}
</script>
