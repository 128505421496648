<template>
  <RevCard class="mr-72 mt-20 px-24">
    <!-- TODO BBACK-1485, fix RevList/RevListItem in DS and migrate -->
    <div
      v-for="(item, index) in props.summary"
      :key="item.answer"
      :class="getListItemClass(index)"
    >
      <p class="body-1">
        {{ item.question }}
      </p>
      <p class="body-1 whitespace-nowrap">
        {{ item.answer }}
      </p>
    </div>
  </RevCard>
</template>

<script lang="ts" setup>
import type { SummaryItem } from '@backmarket/http-api/src/api-specs-buyback/customer/getQuestionsV3'
import { tw } from '@backmarket/utils/string/tw'
import { RevCard } from '@ds/components/Card'

export interface DeviceSummaryProps {
  summary: Array<SummaryItem>
}

const props = withDefaults(defineProps<DeviceSummaryProps>(), {
  summary: () => [],
})

const getListItemClass = (index: number) => {
  const common = tw`flex justify-between py-20`
  const nthItem = index > 0 ? tw`border-static-default-low border-t` : ''

  return [common, nthItem]
}
</script>
